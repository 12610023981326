import type { Step, RouteName } from "~ui/components/ProgressIndicator.vue"

export function useOnboarding() {
  const completedSteps = useCookie<RouteName[]>("completed_steps", {
    default: () => [],
  })

  const quizAnswers = useState<Record<string, number>>(() => ({}))

  const { t } = useI18n()
  const steps = computed(
    () =>
      [
        {
          link: { name: "onboarding-quiz" },
          title: t("dynamic_keys.onboarding.steps.introduction"),
        },
        {
          link: { name: "onboarding-quiz-quiz" },
          title: t("dynamic_keys.onboarding.steps.quiz"),
        },
        {
          link: { name: "onboarding-quiz-finished" },
          title: t("dynamic_keys.onboarding.steps.introduction"),
          hidden: true,
        },
      ] satisfies Step[],
  )

  const route = useRoute()
  function completeStep() {
    if (!route.name) return

    if (route.name && !completedSteps.value.includes(route.name)) {
      completedSteps.value = [...completedSteps.value, route.name]
    }
  }

  return {
    completedSteps,
    steps,
    completeStep,
    quizAnswers,
  }
}
